import store from 'store2';
import Singleton from '../../apps/commons/utils/singleton';

@Singleton
class localStorageManager {
  constructor() {
    this.store = store.namespace('provider');
  }

  clearStorage() {
    this.store.clear();
  }

  getJwtToken() {
    return this.store.get('token');
  }
  setJwtToken(token) {
    if (token) {
      this.store.set('token', token);
    }
  }

  getActivePatientsUri() {
    return this.store.get('activePatientsUri');
  }
  setActivePatientsUri(activePatientsUri) {
    if (activePatientsUri) {
      this.store.set('activePatientsUri', activePatientsUri);
    }
  }

  getpatientPairedPatientIdsUri() {
    return this.store.get('patientPairedPatientIdsUri');
  }
  setpatientPairedPatientIdsUri(patientPairedPatientIdsUri) {
    if (patientPairedPatientIdsUri) {
      this.store.set('patientPairedPatientIdsUri', patientPairedPatientIdsUri);
    }
  }
  getPatientsUri() {
    return this.store.get('patientsUri');
  }
  setPatientsUri(patientsUri) {
    if (patientsUri) {
      this.store.set('patientsUri', patientsUri);
    }
  }

  getPatientsUriNew() {
    return this.store.get('patientsUriNew');
  }
  setPatientsUriNew(patientsUriNew) {
    if (patientsUriNew) {
      this.store.set('patientsUriNew', patientsUriNew);
    }
  }

  getUnpairedPatientsUri() {
    return this.store.get('unpairedPatientsUri');
  }
  setUnpairedPatientsUri(unpairedPatientsUri) {
    if (unpairedPatientsUri) {
      this.store.set('unpairedPatientsUri', unpairedPatientsUri);
    }
  }

  getUnpairedPatientsUriNew() {
    return this.store.get('unpairedPatientsUriNew');
  }
  setUnpairedPatientsUriNew(unpairedPatientsUriNew) {
    if (unpairedPatientsUriNew) {
      this.store.set('unpairedPatientsUriNew', unpairedPatientsUriNew);
    }
  }

  getArchivePatientsUri() {
    return this.store.get('archivePatientsUri');
  }
  setArchivePatientsUri(archivePatientsUri) {
    if (archivePatientsUri) {
      this.store.set('archivePatientsUri', archivePatientsUri);
    }
  }

  getimportReportDownloadUri() {
    return this.store.get('importReportDownloadUri');
  }
  setimportReportDownloadUri(importReportDownloadUri) {
    if (importReportDownloadUri) {
      this.store.set('importReportDownloadUri', importReportDownloadUri);
    }
  }

  getLockedPatientsUri() {
    return this.store.get('lockedPatientsUri');
  }
  setLockedPatientsUri(lockedPatientsUri) {
    if (lockedPatientsUri) {
      this.store.set('lockedPatientsUri', lockedPatientsUri);
    }
  }
  getimportPatientsInventoryUri() {
    return this.store.get('importPatientsInventoryUri');
  }
  setimportPatientsInventoryUri(importPatientsInventoryUri) {
    if (importPatientsInventoryUri) {
      this.store.set('importPatientsInventoryUri', importPatientsInventoryUri);
    }
  }

  getPatientsImportUri() {
    return this.store.get('patientsImportUri');
  }
  setPatientsImportUri(patientsImportUri) {
    if (patientsImportUri) {
      this.store.set('patientsImportUri', patientsImportUri);
    }
  }

  getPatientsInviteUri() {
    return this.store.get('resendInviteEmailsPatientsUri');
  }
  setPatientsInviteUri(resendInviteEmailsPatientsUri) {
    if (resendInviteEmailsPatientsUri) {
      this.store.set('resendInviteEmailsPatientsUri', resendInviteEmailsPatientsUri);
    }
  }

  getAddEmployeesUri() {
    return this.store.get('addEmployeesUri');
  }
  setAddEmployeesUri(addEmployeeUri) {
    if (addEmployeeUri) {
      this.store.set('addEmployeesUri', addEmployeeUri);
    }
  }

  getAddAdminsUri() {
    return this.store.get('addAdminsUri');
  }
  setAddAdminsUri(addAdminsUri) {
    if (addAdminsUri) {
      this.store.set('addAdminsUri', addAdminsUri);
    }
  }

  getAddPhysicianUri() {
    return this.store.get('addPhysicianUri');
  }
  setAddPhysicianUri(addPhysicianUri) {
    if (addPhysicianUri) {
      this.store.set('addPhysicianUri', addPhysicianUri);
    }
  }

  getAddPatientUri() {
    return this.store.get('addPatientUri');
  }
  setAddPatientUri(addPatientUri) {
    if (addPatientUri) {
      this.store.set('addPatientUri', addPatientUri);
    }
  }

  getArchivePatientUri() {
    return this.store.get('archivePatientUri');
  }
  setArchivePatientUri(archivePatientUri) {
    if (archivePatientUri) {
      this.store.set('archivePatientUri', archivePatientUri);
    }
  }

  getEmployeesUri() {
    return this.store.get('employeesUri');
  }
  setEmployeesUri(employeesUri) {
    if (employeesUri) {
      this.store.set('employeesUri', employeesUri);
    }
  }

  getActiveEmployeesUri() {
    return this.store.get('employeesUri').concat('/active');
  }
  setActiveEmployeesUri(employeesUri) {
    if (employeesUri) {
      this.store.set('employeesUri', employeesUri);
    }
  }

  getUnpairedEmployeesUri() {
    return this.store.get('unpairedEmployeesUri');
  }
  setUnpairedEmployeesUri(unpairedEmployeesUri) {
    if (unpairedEmployeesUri) {
      this.store.set('unpairedEmployeesUri', unpairedEmployeesUri);
    }
  }

  getLockedEmployeesUri() {
    return this.store.get('lockedEmployeesUri');
  }
  setLockedEmployeesUri(lockedEmployeesUri) {
    if (lockedEmployeesUri) {
      this.store.set('lockedEmployeesUri', lockedEmployeesUri);
    }
  }

  getEmployeesImportUri() {
    return this.store.get('employeesImportUri');
  }
  setEmployeesImportUri(employeesImportUri) {
    if (employeesImportUri) {
      this.store.set('employeesImportUri', employeesImportUri);
    }
  }

  getEmployeesInviteUri() {
    return this.store.get('resendInviteEmailsStaffsUri');
  }
  setEmployeesInviteUri(resendInviteEmailsStaffsUri) {
    if (resendInviteEmailsStaffsUri) {
      this.store.set('resendInviteEmailsStaffsUri', resendInviteEmailsStaffsUri);
    }
  }

  getActiveEmployeesForSchedulerUri() {
    return this.store.get('activeEmployeesForSchedulerUri');
  }
  setActiveEmployeesForSchedulerUri(activeEmployeesForSchedulerUri) {
    if (activeEmployeesForSchedulerUri) {
      this.store.set('activeEmployeesForSchedulerUri', activeEmployeesForSchedulerUri);
    }
  }

  getTemplatesUri() {
    return this.store.get('templatesUri');
  }
  setTemplatesUri(templatesUri) {
    if (templatesUri) {
      this.store.set('templatesUri', templatesUri);
    }
  }

  getQuestionsUri() {
    return this.store.get('questionsUri');
  }
  setQuestionsUri(token) {
    if (token) {
      this.store.set('questionsUri', token);
    }
  }

  getExceptionTypeUri() {
    return this.store.get('exceptionTypeUri');
  }

  setExceptionTypeUri(token) {
    if (token) {
      this.store.set('exceptionTypeUri', token);
    }
  }

  getOrganizationUri() {
    return this.store.get('organizationUri');
  }
  setOrganizationUri(organizationUri) {
    if (organizationUri) {
      this.store.set('organizationUri', organizationUri);
    }
  }

  getPatientUri() {
    return this.store.get('patientUri');
  }
  setPatientUri(patientUri) {
    if (patientUri) {
      this.store.set('patientUri', patientUri);
    }
  }

  getSurveyUri() {
    return this.store.get('surveyUri');
  }
  setSurveyUri(surveyUri) {
    if (surveyUri) {
      this.store.set('surveyUri', surveyUri);
    }
  }

  getSurveyCountUri() {
    return this.store.get('surveysCountUri');
  }
  setSurveyCountUri(surveysCountUri) {
    if (surveysCountUri) {
      this.store.set('surveysCountUri', surveysCountUri);
    }
  }

  getCompletedSurveysUri() {
    return this.store.get('completedSurveysUri');
  }
  setCompletedSurveysUri(surveyUri) {
    if (surveyUri) {
      this.store.set('completedSurveysUri', surveyUri);
    }
  }

  getPatientsAutocompleteUri() {
    return this.store.get('patientsAutocompleteUri');
  }
  setPatientsAutocompleteUri(patientsAutocompleteUri) {
    if (patientsAutocompleteUri) {
      this.store.set('patientsAutocompleteUri', patientsAutocompleteUri);
    }
  }
  setPhysiciansAutocompleteUri(physiciansAutocompleteUri) {
    if (physiciansAutocompleteUri) {
      this.store.set('physiciansAutocompleteUri', physiciansAutocompleteUri);
    }
  }

  getPhysiciansAutocompleteUri() {
    return this.store.get('physiciansAutocompleteUri');
  }

  setTemplatesAutocompleteUri(templatesAutocompleteUri) {
    if (templatesAutocompleteUri) {
      this.store.set('templatesAutocompleteUri', templatesAutocompleteUri);
    }
  }

  getTemplatesAutocompleteUri() {
    return this.store.get('templatesAutocompleteUri');
  }

  setTemplatesLiteAutocompleteUri(templatesLiteAutocompleteUri) {
    if (templatesLiteAutocompleteUri) {
      this.store.set('templatesLiteAutocompleteUri', templatesLiteAutocompleteUri);
    }
  }

  getTemplatesLiteAutocompleteUri() {
    return this.store.get('templatesLiteAutocompleteUri');
  }

  getNeedsReviewUri() {
    return this.store.get('inProgressSurveysUri');
  }
  setNeedsReviewUri(surveyUri) {
    if (surveyUri) {
      this.store.set('inProgressSurveysUri', surveyUri);
    }
  }
  getPendingResponseUri() {
    return this.store.get('sentSurveysUri');
  }
  setPendingResponseUri(surveyUri) {
    if (surveyUri) {
      this.store.set('sentSurveysUri', surveyUri);
    }
  }

  getCurrentUser() {
    return JSON.parse(this.store.get('currentUser'));
  }
  setCurrentUser(currentUser) {
    if (currentUser) {
      this.store.set('currentUser', JSON.stringify(currentUser));
    }
  }

  setUserRole(userRoll) {
    if (userRoll) {
      this.store.set('userRoll', userRoll);
    }
  }

  getUserRole(userRoll) {
    return this.store.get('userRoll');
  }

  getCurrentOrganization() {
    return JSON.parse(this.store.get('currentOrganization'));
  }
  setCurrentOrganization(currentOrganization) {
    if (currentOrganization) {
      this.store.set('currentOrganization', JSON.stringify(currentOrganization));
    }
  }
  getEmployee() {
    return JSON.parse(this.store.get('employee'));
  }
  setEmployee(currentEmployee) {
    if (currentEmployee) {
      this.store.set('employee', JSON.stringify(currentEmployee));
    }
  }

  getEmployeesAutocompleteUri() {
    return this.store.get('employeesAutocompleteUri');
  }

  setEmployeesAutocompleteUri(employeesAutocompleteUri) {
    if (employeesAutocompleteUri)
      this.store.set('employeesAutocompleteUri', employeesAutocompleteUri);
  }

  getPermissions() {
    return JSON.parse(this.store.get('permissions'));
  }
  setPermissions(permissions) {
    if (permissions) {
      this.store.set('permissions', JSON.stringify(permissions));
    }
  }

  getSidebar() {
    return JSON.parse(this.store.get('sidebar'));
  }
  setSidebar(sidebar) {
    if (sidebar) {
      this.store.set('sidebar', JSON.stringify(sidebar));
    }
  }

  getHelpVideoUri() {
    return JSON.parse(this.store.get('helpVideoUri'));
  }
  setHelpVideoUri(helpVideoUri) {
    if (helpVideoUri) {
      this.store.set('helpVideoUri', JSON.stringify(helpVideoUri));
    }
  }

  getReportsUri() {
    return JSON.parse(this.store.get('reportsUri'));
  }
  setReportsUri(reportsUri) {
    if (reportsUri) {
      this.store.set('reportsUri', JSON.stringify(reportsUri));
    }
  }

  getPhysiciansUri() {
    return this.store.get('physiciansUri');
  }

  setPhysiciansUri(physiciansUri) {
    if (physiciansUri) {
      this.store.set('physiciansUri', physiciansUri);
    }
  }

  getPhysiciansImportUri() {
    return this.store.get('physiciansImportUri');
  }
  setPhysiciansImportUri(physiciansImportUri) {
    if (physiciansImportUri) {
      this.store.set('physiciansImportUri', physiciansImportUri);
    }
  }

  getImportPatientsForSurveyAssignUri() {
    return this.store.get('importPatientsForSurveyAssignUri');
  }
  setImportPatientsForSurveyAssignUri(importPatientsForSurveyAssignUri) {
    if (importPatientsForSurveyAssignUri) {
      this.store.set('importPatientsForSurveyAssignUri', importPatientsForSurveyAssignUri);
    }
  }

  getSurveyScheduleUri() {
    return this.store.get('surveyScheduleUri');
  }
  setSurveyScheduleUri(surveyScheduleUri) {
    if (surveyScheduleUri) {
      this.store.set('surveyScheduleUri', surveyScheduleUri);
    }
  }

  getSchedulerUri() {
    return this.getSurveyScheduleUri();
  }

  getTopicsUri() {
    return this.store.get('topicsUri');
  }
  setTopicsUri(topicsUri) {
    if (topicsUri) {
      this.store.set('topicsUri', topicsUri);
    }
  }

  getTopicsFilterUri() {
    return this.store.get('topicsFilterUri');
  }
  setTopicsFilterUri(topicsFilterUri) {
    if (topicsFilterUri) {
      this.store.set('topicsFilterUri', topicsFilterUri);
    }
  }

  getDocumentsUri() {
    return this.store.get('documentsUri');
  }
  setDocumentsUri(documentsUri) {
    if (documentsUri) {
      this.store.set('documentsUri', documentsUri);
    }
  }

  getDocumentsAssignUri() {
    return this.store.get('documentsAssignUri');
  }
  setDocumentsAssignUri(documentsAssignUri) {
    if (documentsAssignUri) {
      this.store.set('documentsAssignUri', documentsAssignUri);
    }
  }

  getDocumentsSheduledUri() {
    return this.store.get('documentsScheduledUri');
  }
  setDocumentsSheduledUri(documentsScheduledUri) {
    if (documentsScheduledUri) {
      this.store.set('documentsScheduledUri', documentsScheduledUri);
    }
  }

  getCategoriesUri() {
    return this.store.get('categoriesUri');
  }
  setCategoriesUri(categoriesUri) {
    if (categoriesUri) {
      this.store.set('categoriesUri', categoriesUri);
    }
  }

  getCategoriesFilterUri() {
    return this.store.get('categoriesFilterUri');
  }
  setCategoriesFilterUri(categoriesFilterUri) {
    if (categoriesFilterUri) {
      this.store.set('categoriesFilterUri', categoriesFilterUri);
    }
  }

  getUserOrganizationFeaturesListUri() {
    return this.store.get('userOrganizationFeaturesListUri');
  }
  setUserOrganizationFeaturesListUri(userOrganizationFeaturesListUri) {
    if (userOrganizationFeaturesListUri) {
      this.store.set('userOrganizationFeaturesListUri', userOrganizationFeaturesListUri);
    }
  }

  getAppConfigurationListUri() {
    return this.store.get('appConfigurationListUri');
  }
  setAppConfigurationListUri(appConfigurationListUri) {
    if (appConfigurationListUri) {
      this.store.set('appConfigurationListUri', appConfigurationListUri);
    }
  }

  getMetricConfigurationListUri() {
    return this.store.get('metricConfigurationListUri');
  }
  setMetricConfigurationListUri(metricConfigurationListUri) {
    if (metricConfigurationListUri) {
      this.store.set('metricConfigurationListUri', metricConfigurationListUri);
    }
  }

  getMetricDashboardCensusUri() {
    return this.store.get('metricDashboardCensusUri');
  }
  setMetricDashboardCensusUri(metricDashboardCensusUri) {
    if (metricDashboardCensusUri) {
      this.store.set('metricDashboardCensusUri', metricDashboardCensusUri);
    }
  }

  getMetricDashboardEngagementUri() {
    return this.store.get('metricDashboardEngagementUri');
  }
  setMetricDashboardEngagementUri(metricDashboardEngagementUri) {
    if (metricDashboardEngagementUri) {
      this.store.set('metricDashboardEngagementUri', metricDashboardEngagementUri);
    }
  }

  getSelectedCategoryId() {
    return this.store.get('currentCategory');
  }
  setSelectedCategoryId(selectedCategoryId) {
    if (selectedCategoryId) {
      this.store.set('currentCategory', selectedCategoryId);
    }
  }
  getMacroReportsUri() {
    return this.store.get('macroReportsUri');
  }
  setMacroReportsUri(macroReportsUri) {
    if (macroReportsUri) {
      this.store.set('macroReportsUri', macroReportsUri);
    }
  }
  setMacroReportsCreatetUri(macroReportsCreateUri) {
    if (macroReportsCreateUri) {
      this.store.set('macroReportsCreateUri', macroReportsCreateUri);
    }
  }
  getMacroReportsCreatetUri() {
    return this.store.get('macroReportsCreateUri');
  }

  setVisualIndicatorUri(needVisualIndicatorUri) {
    if (needVisualIndicatorUri) {
      this.store.set('needVisualIndicatorUri', needVisualIndicatorUri);
    }
  }
  getVisualIndicatorUri() {
    return this.store.get('needVisualIndicatorUri');
  }

  setCompanyReportFieldsUri(getCompanyReportFieldsUri) {
    if (getCompanyReportFieldsUri) {
      return this.store.set('getCompanyReportFieldsUri', getCompanyReportFieldsUri);
    }
  }
  getCompanyReportFieldsUri() {
    return this.store.get('getCompanyReportFieldsUri');
  }

  getSignableDocumentsUri() {
    return this.store.get('signDocumentsUri');
  }
  setSignableDocumentsUri(signDocumentsUri) {
    if (signDocumentsUri) {
      this.store.set('signDocumentsUri', signDocumentsUri);
    }
  }

  getSignableDocumentsAssignUri() {
    return this.store.get('signDocumentsAssignUri');
  }
  setSignableDocumentsAssignUri(signDocumentsAssignUri) {
    if (signDocumentsAssignUri) {
      this.store.set('signDocumentsAssignUri', signDocumentsAssignUri);
    }
  }

  getSignableDocumentsScheduledUri() {
    return this.store.get('signDocumentsScheduledUri');
  }
  setSignableDocumentsScheduledUri(signDocumentsScheduledUri) {
    if (signDocumentsScheduledUri) {
      this.store.set('signDocumentsScheduledUri', signDocumentsScheduledUri);
    }
  }

  getItemsUri() {
    return this.store.get('itemsUri');
  }
  setItemsUri(itemsUri) {
    if (itemsUri) {
      this.store.set('itemsUri', itemsUri);
    }
  }

  getItemsImportUri() {
    return this.store.get('itemsImportUri');
  }
  setItemsImportUri(itemsImportUri) {
    if (itemsImportUri) {
      this.store.set('itemsImportUri', itemsImportUri);
    }
  }

  getItemGroupsUri() {
    return this.store.get('itemGroupsUri');
  }
  setItemGroupsUri(itemGroupsUri) {
    if (itemGroupsUri) {
      this.store.set('itemGroupsUri', itemGroupsUri);
    }
  }

  getTherapiesUri() {
    return this.store.get('therapiesUri');
  }
  setTherapiesUri(therapiesUri) {
    if (therapiesUri) {
      this.store.set('therapiesUri', therapiesUri);
    }
  }

  getEventsUri() {
    return this.store.get('eventsUri');
  }
  setEventsUri(eventsUri) {
    if (eventsUri) {
      this.store.set('eventsUri', eventsUri);
    }
  }

  getImportEventsUri() {
    return this.store.get('importEventsUri');
  }

  getExportTherapyUri() {
    return this.store.get('exportTherapyUri');
  }

  setExportTherapyUri(exportTherapyUri) {
    if (exportTherapyUri) {
      return this.store.set('exportTherapyUri', exportTherapyUri);
    }
  }

  setImportEventsUri(importEventsUri) {
    if (importEventsUri) {
      return this.store.set('importEventsUri', importEventsUri);
    }
  }

  getAssignStaffPatientsUri() {
    return this.store.get('assignStaffPatientsUri');
  }
  setAssignStaffPatientsUri(assignStaffPatientsUri) {
    if (!assignStaffPatientsUri) {
      return;
    }
    this.store.set('assignStaffPatientsUri', assignStaffPatientsUri);
  }

  getAssignStaffCategoriesUri() {
    return this.store.get('assignStaffCategoriesUri');
  }
  setAssignStaffCategoriesUri(assignStaffCategoriesUri) {
    if (!assignStaffCategoriesUri) {
      return;
    }
    this.store.set('assignStaffCategoriesUri', assignStaffCategoriesUri);
  }
  setActivePatientsForSchedulerUri(activePatientsForSchedulerUri) {
    if (!activePatientsForSchedulerUri) return;
    this.store.set('activePatientsForSchedulerUri', activePatientsForSchedulerUri);
  }
  getActivePatientsForSchedulerUri() {
    return this.store.get('activePatientsForSchedulerUri');
  }

  setBulkCompleteUri(bulkCompleteUri) {
    if (!bulkCompleteUri) {
      return;
    }
    this.store.set('bulkCompleteUri', bulkCompleteUri);
  }

  getBulkCompleteUri() {
    return this.store.get('bulkCompleteUri');
  }

  setBulkCompleteNoteUri(bulkCompleteNoteUri) {
    if (!bulkCompleteNoteUri) {
      return;
    }
    this.store.set('bulkCompleteNoteUri', bulkCompleteNoteUri);
  }

  getBulkCompleteNoteUri() {
    return this.store.get('bulkCompleteNoteUri');
  }

  setPatientImportUri(patientImportUri) {
    if (!patientImportUri) {
      return;
    }
    this.store.set('patientImportUri', patientImportUri);
  }

  getPatientImportUri() {
    return this.store.get('patientImportUri');
  }
  getExceptionPriorityUri() {
    return this.store.get('exceptionPriorityUri');
  }
  setExceptionPriorityUri(exceptionPriorityUri) {
    if (exceptionPriorityUri) {
      this.store.set('exceptionPriorityUri', exceptionPriorityUri);
    }
  }

  getExceptionPriorityListUri() {
    return this.store.get('exceptionPriorityListUri');
  }
  setExceptionPriorityListUri(exceptionPriorityListUri) {
    if (exceptionPriorityListUri) {
      this.store.set('exceptionPriorityListUri', exceptionPriorityListUri);
    }
  }
  getproviderMessagesUri() {
    return this.store.get('providerMessagesUri');
  }

  setproviderMessagesUri(providerMessagesUri) {
    if (providerMessagesUri) {
      this.store.set('providerMessagesUri', providerMessagesUri);
    }
  }

  getproviderAnnouncementUri() {
    return this.store.get('providerAnnouncementUri');
  }
  setproviderAnnouncementUri(providerAnnouncementUri) {
    if (providerAnnouncementUri) {
      this.store.set('providerAnnouncementUri', providerAnnouncementUri);
    }
  }

  getproviderAnnouncementGetUri(){
    return this.store.get('providerAnnouncementGetUri')
  }
  setproviderAnnouncementGetUri(providerAnnouncementGetUri){
    if(providerAnnouncementGetUri){
      this.store.set('providerAnnouncementGetUri',providerAnnouncementGetUri)
    }
  }

  setdiscussionIndicatorUri(discussionIndicatorUri) {
    if (discussionIndicatorUri) {
      this.store.set('discussionIndicatorUri', discussionIndicatorUri);
    }
  }
  getdiscussionIndicatorUri() {
    return this.store.get('discussionIndicatorUri');
  }
  setafterHoursConfigUri(afterHoursConfigUri) {
    if (afterHoursConfigUri) {
      this.store.set('afterHoursConfigUri', afterHoursConfigUri);
    }
  }
  getAfterHoursConfigUri() {
    return this.store.get('afterHoursConfigUri');
  }
  getsurveyOrganizationListOrdersUri() {
    return this.store.get('surveyOrganizationListOrdersUri');
  }
  setsurveyOrganizationListOrdersUri(surveyOrganizationListOrdersUri) {
    if (surveyOrganizationListOrdersUri) {
      this.store.set('surveyOrganizationListOrdersUri', surveyOrganizationListOrdersUri);
    }
  }
  setAutoCompleteCategoryUri(categoriesAutocompleteUri) {
    if (categoriesAutocompleteUri) {
      this.store.set('categoriesAutocompleteUri', categoriesAutocompleteUri);
    }
  }
  getAutoCompleteCategoryUri() {
    return this.store.get('categoriesAutocompleteUri');
  }
  setsuspendProviderSurveyInstance(suspendProviderSurveyInstance) {
    if (suspendProviderSurveyInstance) {
      this.store.set('suspendProviderSurveyInstance', suspendProviderSurveyInstance);
    }
  }
  getsuspendProviderSurveyInstance() {
    return this.store.get('suspendProviderSurveyInstance');
  }

  setSurveyNeedsReviewAllUri(surveyNeedsReviewAll) {
    if (surveyNeedsReviewAll) {
      this.store.set('surveyNeedsReviewAll', surveyNeedsReviewAll);
    }
  }
  getSurveyNeedsReviewAllUri() {
    return this.store.get('surveyNeedsReviewAll');
  }

  setSurveyUnassignUri(setSurveyUnassignUri) {
    if (setSurveyUnassignUri) {
      this.store.set('setSurveyUnassignUri', setSurveyUnassignUri);
    }
  }
  getSurveyUnassignUri() {
    return this.store.get('setSurveyUnassignUri');
  }

  setSurveyAssignUri(surveyAssignUri) {
    if (surveyAssignUri) {
      this.store.set('surveyAssignUri', surveyAssignUri);
    }
  }
  getSurveyAssignUri() {
    return this.store.get('surveyAssignUri');
  } 

  setfailedPairingPatientsUri(failedPairingPatientsUri) {
    if (failedPairingPatientsUri) {
      this.store.set('failedPairingPatientsUri', failedPairingPatientsUri);
    }
  }
  getfailedPairingPatientsUri() {
    return this.store.get('failedPairingPatientsUri');
  }

  setReorderColumnUri(reorderColumnUri) {
    if(reorderColumnUri) {
      this.store.set('reorderColumnUri', reorderColumnUri);
    }
  }
  getReorderColumnUri(){
    return this.store.get('reorderColumnUri');
  }
}

export default localStorageManager.getInstance();
